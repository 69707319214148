import { Component, Element, h, Prop, State, Watch } from '@stencil/core';
import { __ } from '@wordpress/i18n';
import { state as checkoutState, onChange as onCheckoutChange } from '@store/checkout';
import { TaxProtocol } from '../../../types';

@Component({
  tag: 'sc-form-components-validator',
  shadow: true,
})
export class ScFormComponentsValidator {
  @Element() el: HTMLScFormComponentsValidatorElement;

  private removeCheckoutListener: () => void;

  /** Disable validation? */
  @Prop() disabled: boolean;

  /** The tax protocol */
  @Prop() taxProtocol: TaxProtocol;

  /** Is there an address field? */
  @State() hasAddress: boolean;

  /** Is there a tax id field? */
  @State() hasTaxIDField: boolean;

  /** Is there a bumps field? */
  @State() hasBumpsField: boolean;

  /** Is there a tax line? */
  @State() hasTaxLine: boolean;

  /** Is there a bump line? */
  @State() hasBumpLine: boolean;

  /** Is there shipping choices */
  @State() hasShippingChoices: boolean;
  /** Is there a shipping amount */
  @State() hasShippingAmount: boolean;

  handleOrderChange() {
    // bail if we don't have address invalid error or disabled.
    if (this.disabled) return;

    // make sure to add the address field if it's not there.
    if (checkoutState.checkout?.tax_status === 'address_invalid' || checkoutState.checkout?.shipping_enabled || checkoutState.checkout?.shipping_address_required) {
      this.addAddressField();
    }

    // add order bumps.
    if (checkoutState.checkout?.recommended_bumps?.data?.length) {
      this.addBumps();
    }
    if (!!checkoutState.checkout?.tax_amount) {
      this.addTaxLine();
    }

    // add shipping choices.
    if (checkoutState.checkout?.shipping_enabled && checkoutState.checkout?.selected_shipping_choice_required) {
      this.addShippingChoices();
    }

    if (!!checkoutState.checkout?.shipping_amount) {
      this.addShippingAmount();
    }
  }

  @Watch('hasAddress')
  handleHasAddressChange() {
    if (!this.hasAddress) return;
    this.handleShippingAddressRequired();
  }

  componentWillLoad() {
    this.hasAddress = !!this.el.querySelector('sc-order-shipping-address');
    this.hasTaxIDField = !!this.el.querySelector('sc-order-tax-id-input');
    this.hasBumpsField = !!this.el.querySelector('sc-order-bumps');
    this.hasTaxLine = !!this.el.querySelector('sc-line-item-tax');
    this.hasShippingChoices = !!this.el.querySelector('sc-shipping-choices');
    this.hasShippingAmount = !!this.el.querySelector('sc-line-item-shipping');

    // automatically add address field if tax is enabled.
    if (this.taxProtocol?.tax_enabled) {
      this.addAddressField();

      // if eu vat is required, add the tax id field.
      if (this.taxProtocol?.eu_vat_required) {
        this.addTaxIDField();
      }
    }

    this.removeCheckoutListener = onCheckoutChange('checkout', () => this.handleOrderChange());
  }

  disconnectedCallback() {
    this.removeCheckoutListener();
  }

  handleShippingAddressRequired() {
    if (!checkoutState.checkout?.shipping_address_required) return;

    // get the address
    const address = this.el.querySelector('sc-order-shipping-address');
    if (!address) return;

    // require the address.
    address.required = true;

    // if we have a customer name field, require that.
    const customerName = this.el.querySelector('sc-customer-name');
    if (!!customerName) {
      customerName.required = true;
      return;
    }

    // require the name and show the name input.
    address.requireName = true;
    address.showName = true;
  }

  addAddressField() {
    if (this.hasAddress) {
      return;
    }

    const payment = this.el.querySelector('sc-payment');
    const address = document.createElement('sc-order-shipping-address');
    address.label = __('Address', 'surecart');
    payment.parentNode.insertBefore(address, payment);
    this.hasAddress = true;
  }

  addTaxIDField() {
    if (this.hasTaxIDField) return;
    const payment = this.el.querySelector('sc-payment');
    const taxInput = document.createElement('sc-order-tax-id-input');
    payment.parentNode.insertBefore(taxInput, payment);
    this.hasTaxIDField = true;
  }

  addBumps() {
    if (this.hasBumpsField) return;
    const payment = this.el.querySelector('sc-payment');
    const bumps = document.createElement('sc-order-bumps');
    payment.parentNode.insertBefore(bumps, payment.nextSibling);
    this.hasBumpsField = true;
  }

  addTaxLine() {
    if (this.hasTaxLine) return;
    const total = this.el.querySelector('sc-line-item-total[total=total]');
    const tax = document.createElement('sc-line-item-tax');
    if (total?.previousElementSibling?.tagName === 'SC-DIVIDER') {
      total.parentNode.insertBefore(tax, total.previousElementSibling);
    } else {
      total.parentNode.insertBefore(tax, total);
    }
    this.hasTaxLine = true;
  }

  addShippingChoices() {
    if (this.hasShippingChoices) return;

    const payment = this.el.querySelector('sc-payment');
    const shippingChoices = document.createElement('sc-shipping-choices');
    payment.parentNode.insertBefore(shippingChoices, payment);
    this.hasShippingChoices = true;
  }

  addShippingAmount() {
    if (this.hasShippingAmount) return;

    let insertBeforeElement: Element = this.el.querySelector('sc-line-item-tax');
    const total = this.el.querySelector('sc-line-item-total[total=total]');

    if (!insertBeforeElement) {
      insertBeforeElement = total?.previousElementSibling?.tagName === 'SC-DIVIDER' ? total.previousElementSibling : total;
    }

    const shippingAmount = document.createElement('sc-line-item-shipping');
    insertBeforeElement.parentNode.insertBefore(shippingAmount, insertBeforeElement);
    this.hasShippingAmount = true;
  }

  render() {
    return <slot />;
  }
}
