import { Component, Element, Event, EventEmitter, h, Listen, Method, Prop, Watch } from '@stencil/core';
import { state as checkoutState } from '@store/checkout';
import { clearCheckout } from '@store/checkout/mutations';
import { state as selectedProcessor } from '@store/selected-processor';
import { __ } from '@wordpress/i18n';
import { addQueryArgs, getQueryArg, getQueryArgs, removeQueryArgs } from '@wordpress/url';
import { updateFormState } from '@store/form/mutations';

import { parseFormData } from '../../../functions/form-data';
import { createOrUpdateCheckout, fetchCheckout, finalizeCheckout } from '../../../services/session';
import { Checkout, FormStateSetter, LineItemData, PriceChoice } from '../../../types';
import { createErrorNotice, createInfoNotice, removeNotice } from '@store/notices/mutations';

@Component({
  tag: 'sc-session-provider',
  shadow: true,
})
export class ScSessionProvider {
  /** Element */
  @Element() el: HTMLElement;

  /** An array of prices to pre-fill in the form. */
  @Prop() prices: Array<PriceChoice> = [];

  /** Should we persist the session. */
  @Prop() persist: boolean = true;

  /** Update line items event */
  @Event() scUpdateOrderState: EventEmitter<Checkout>;

  /** Update line items event */
  @Event() scUpdateDraftState: EventEmitter<Checkout>;

  @Event() scPaid: EventEmitter<void>;

  /** Set the state */
  @Event() scSetState: EventEmitter<FormStateSetter>;

  @Watch('prices')
  handlePricesChange() {
    let line_items = this.addInitialPrices() || [];
    // line_items = this.addPriceChoices(line_items);
    if (!line_items?.length) {
      return;
    }
    return this.loadUpdate({ line_items });
  }

  /**
   * Finalize the order.
   *
   * @returns {Promise<Order>}
   */
  @Method()
  async finalize() {
    return await this.handleFormSubmit();
  }

  async getFormData() {
    let data = {};
    const form = this.el.querySelector('sc-form');
    if (form) {
      const json = await form.getFormJson();
      data = parseFormData(json);
    }
    return data;
  }

  /**
   * Handles the form submission.
   * @param e
   */
  @Listen('scFormSubmit')
  async handleFormSubmit() {
    removeNotice();

    updateFormState('FINALIZE');

    // Get current form state.
    let data = await this.getFormData();

    if (window?.scData?.recaptcha_site_key && window?.grecaptcha) {
      try {
        data['grecaptcha'] = await window.grecaptcha.execute(window.scData.recaptcha_site_key, { action: 'surecart_checkout_submit' });
      } catch (e) {
        console.error(e);
        updateFormState('REJECT');
        this.handleErrorResponse(e);
        return new Error(e?.message);
      }
    }

    // first lets make sure the session is updated before we process it.
    try {
      await this.update(data);
    } catch (e) {
      console.error(e);
      updateFormState('REJECT');
      this.handleErrorResponse(e);
    }

    // first validate server-side and get key
    try {
      checkoutState.checkout = await finalizeCheckout({
        id: checkoutState?.checkout?.id,
        query: {
          ...(selectedProcessor?.method ? { payment_method_type: selectedProcessor?.method } : {}),
          return_url: addQueryArgs(window.location.href, {
            ...(checkoutState?.checkout?.id ? { checkout_id: checkoutState?.checkout?.id } : {}),
            is_surecart_payment_redirect: true,
          }),
        },
        data,
        processor: {
          id: selectedProcessor.id,
          manual: selectedProcessor.manual,
        },
      });

      // the checkout is paid.
      if (['paid', 'processing'].includes(checkoutState.checkout?.status)) {
        this.scPaid.emit();
      }

      if (checkoutState.checkout?.payment_intent?.processor_data?.mollie?.checkout_url) {
        updateFormState('PAYING');
        return setTimeout(() => window.location.assign(checkoutState.checkout?.payment_intent?.processor_data?.mollie?.checkout_url), 50);
      }

      setTimeout(() => {
        updateFormState('PAYING');
      }, 50);

      return checkoutState.checkout;
    } catch (e) {
      console.error(e);
      this.handleErrorResponse(e);
      return new Error(e?.message);
    }
  }

  /**
   * Handle paid event and update the
   */
  @Listen('scPaid')
  async handlePaid() {
    updateFormState('PAID');
  }

  @Listen('scUpdateAbandonedCart')
  async handleAbandonedCartUpdate(e) {
    const abandoned_checkout_enabled = e.detail;
    this.loadUpdate({
      abandoned_checkout_enabled,
    });
  }

  /** Handles coupon updates. */
  @Listen('scApplyCoupon')
  async handleCouponApply(e) {
    const promotion_code = e.detail;
    removeNotice();
    this.loadUpdate({
      discount: {
        ...(promotion_code ? { promotion_code } : {}),
      },
    });
  }

  /** Find or create session on load. */
  componentDidLoad() {
    this.findOrCreateOrder();
  }

  /** Find or create an order */
  async findOrCreateOrder() {
    // get URL params.
    const { redirect_status, checkout_id, line_items, coupon, is_surecart_payment_redirect } = getQueryArgs(window.location.href);
    // remove params we don't want.
    window.history.replaceState(
      {},
      document.title,
      removeQueryArgs(window.location.href, 'redirect_status', 'coupon', 'line_items', 'confirm_checkout_id', 'checkout_id', 'no_cart'),
    );

    // handle abandoned checkout.
    if (!!is_surecart_payment_redirect && !!checkout_id) {
      updateFormState('FINALIZE');
      updateFormState('PAYING');
      return this.handleCheckoutIdFromUrl(checkout_id, coupon as string);
    }

    // handle redirect status.
    if (!!redirect_status) {
      return this.handleRedirectStatus(redirect_status, checkout_id);
    }

    // handle abandoned checkout.
    if (!!checkout_id) {
      return this.handleCheckoutIdFromUrl(checkout_id, coupon as string);
    }

    // handle initial line items.
    if (!!line_items) {
      return this.handleInitialLineItems(line_items, coupon as string);
    }

    // we have an existing saved checkout id in the session, and we are persisting.
    const id = checkoutState?.checkout?.id;
    if (id && this.persist) {
      return this.handleExistingCheckout(id, coupon as string);
    }

    return this.handleNewCheckout(coupon as string);
  }

  /** Handle payment instrument redirect status */
  async handleRedirectStatus(status, id) {
    console.info('Handling payment redirect.');
    // status failed.
    if (status === 'failed') {
      createErrorNotice(__('Payment unsuccessful. Please try again.', 'surecart'));
      return;
    }

    // get the
    if (!id) {
      createErrorNotice(__('Could not find checkout. Please contact us before attempting to purchase again.', 'surecart'));
      return;
    }

    // success, refetch the checkout
    try {
      updateFormState('FINALIZE');
      updateFormState('PAID');
      checkoutState.checkout = (await fetchCheckout({
        id,
        query: {
          refresh_status: true,
        },
      })) as Checkout;

      // TODO: should we even check this?
      if (checkoutState.checkout?.status && ['paid', 'processing'].includes(checkoutState.checkout?.status)) {
        setTimeout(() => {
          this.scPaid.emit();
        }, 100);
      }
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  /** Handle abandoned checkout from URL */
  async handleCheckoutIdFromUrl(id, promotion_code = '') {
    console.info('Handling existing checkout from url.', promotion_code, id);

    // if coupon code, load the checkout with the code.
    if (promotion_code) {
      return this.loadUpdate({
        id,
        discount: { promotion_code },
        refresh_price_versions: true,
      });
    }

    try {
      updateFormState('FETCH');
      checkoutState.checkout = (await fetchCheckout({
        id,
        query: {
          refresh_status: true,
        },
      })) as Checkout;

      const isModeMismatch = checkoutState.mode !== (checkoutState.checkout?.live_mode ? 'live' : 'test');

      if (isModeMismatch) {
        console.info('Mode mismatch, creating new checkout.');
        clearCheckout();
        checkoutState.checkout = null;
        await this.handleNewCheckout(promotion_code);
        return;
      }

      updateFormState('RESOLVE');
    } catch (e) {
      this.handleErrorResponse(e);
    }

    // handle status.
    switch (checkoutState.checkout?.status) {
      case 'paid':
      case 'processing':
        return setTimeout(() => {
          updateFormState('FINALIZE');
          updateFormState('PAID');
          this.scPaid.emit();
        }, 100);

      case 'payment_failed':
        clearCheckout();
        createErrorNotice({
          message: __('Payment unsuccessful. Please try again.', 'surecart'),
        });
        return;

      case 'payment_intent_canceled':
      case 'canceled':
        clearCheckout();
        createErrorNotice({
          message: __('Payment canceled. Please try again.', 'surecart'),
        });
        return;

      case 'finalized':
        createErrorNotice({
          message: __('Payment unsuccessful. Please try again.', 'surecart'),
        });
        updateFormState('REJECT');
        return;
    }
  }

  /** Handle line items (and maybe ) */
  async handleInitialLineItems(line_items, promotion_code) {
    console.info('Handling initial line items.');
    // TODO: move this to central store.
    const address = this.el.querySelector('sc-order-shipping-address');
    clearCheckout();
    return this.loadUpdate({
      line_items,
      refresh_price_versions: true,
      ...(promotion_code ? { discount: { promotion_code } } : {}),
      ...(address?.defaultCountry
        ? {
            shipping_address: {
              country: address?.defaultCountry,
            },
          }
        : {}),
    });
  }

  /** Handle a brand new checkout. */
  async handleNewCheckout(promotion_code) {
    // get existing form data from defaults (default country selection, etc).
    const data = this.getFormData();
    let line_items = checkoutState.initialLineItems || [];
    const address = this.el.querySelector('sc-order-shipping-address');

    try {
      updateFormState('FETCH');
      checkoutState.checkout = (await createOrUpdateCheckout({
        data: {
          ...data,
          ...(promotion_code ? { discount: { promotion_code } } : {}),
          ...(address?.defaultCountry
            ? {
                shipping_address: {
                  country: address?.defaultCountry,
                },
              }
            : {}),
          line_items,
          ...(checkoutState.taxProtocol?.eu_vat_required ? { tax_identifier: { number_type: 'eu_vat' } } : {}),
        },
      })) as Checkout;
      updateFormState('RESOLVE');
    } catch (e) {
      console.error(e);
      this.handleErrorResponse(e);
    }
  }

  /** Handle existing checkout */
  async handleExistingCheckout(id, promotion_code) {
    if (!id) return this.handleNewCheckout(promotion_code);
    console.info('Handling existing checkout.');
    try {
      updateFormState('FETCH');
      checkoutState.checkout = (await createOrUpdateCheckout({
        id,
        data: {
          ...(promotion_code ? { discount: { promotion_code } } : {}),
          refresh_price_versions: true,
          ...(checkoutState.taxProtocol?.eu_vat_required ? { tax_identifier: { number_type: 'eu_vat' } } : {}),
        },
      })) as Checkout;
      updateFormState('RESOLVE');
    } catch (e) {
      console.error(e);
      this.handleErrorResponse(e);
    }
  }

  /** Handle the error response. */
  async handleErrorResponse(e) {
    // reinitalize if order not found.
    if (['checkout.not_found'].includes(e?.code)) {
      window.history.replaceState({}, document.title, removeQueryArgs(window.location.href, 'checkout_id'));
      clearCheckout();
      return this.handleNewCheckout(false);
    }

    // one of these is an old price version error.
    if ((e?.additional_errors || []).some(error => error?.code == 'checkout.price.old_version')) {
      await this.loadUpdate({
        id: checkoutState?.checkout?.id,
        data: {
          status: 'draft',
          refresh_price_versions: true,
        },
      });
      createInfoNotice(__('The price a product in your order has changed. We have adjusted your order to the new price.', 'surecart'));
      return;
    }

    // If got Product out of stock error, then fetch the checkout again.
    if (e?.additional_errors?.[0]?.code === 'checkout.product.out_of_stock') {
      this.fetch();
      updateFormState('REJECT');
      return;
    }

    if (['order.invalid_status_transition'].includes(e?.code)) {
      await this.loadUpdate({
        id: checkoutState?.checkout?.id,
        data: {
          status: 'draft',
        },
      });
      this.handleFormSubmit();
      return;
    }

    // expired
    if (e?.code === 'rest_cookie_invalid_nonce') {
      updateFormState('EXPIRE');
      return;
    }

    // paid
    if (e?.code === 'readonly') {
      clearCheckout();
      window.location.assign(removeQueryArgs(window.location.href, 'order'));
      return;
    }

    createErrorNotice(e);
    updateFormState('REJECT');
  }

  /** Looks through children and finds items needed for initial session. */
  async initialize(args = {}) {
    let line_items = checkoutState.initialLineItems || [];
    return this.loadUpdate({ ...(line_items?.length ? { line_items } : {}), ...args });
  }

  /** Add prices that are passed into the component. */
  addInitialPrices() {
    if (!this?.prices?.length) return [];

    // check for id
    if (this.prices.some(p => !p?.id)) {
      return;
    }

    // add prices that are passed into this component.
    return this.prices.map(price => {
      return {
        price_id: price.id,
        quantity: price.quantity,
        variant: price.variant,
      };
    });
  }

  // /** Add default prices that may be selected in form. */
  // addPriceChoices(line_items = []) {
  //   // const elements = this.el.querySelectorAll('[price-id]') as any;
  //   // elements.forEach(el => {
  //   //   // handle price choices.
  //   //   if (el.checked) {
  //   //     line_items.push({
  //   //       quantity: el.quantity || 1,
  //   //       price_id: el.priceId,
  //   //       ...(el.defaultAmount ? { ad_hoc_amount: el.defaultAmount } : {}),
  //   //     });
  //   //   }
  //   //   // handle donation default amount.
  //   //   if (el.defaultAmount) {
  //   //     line_items.push({
  //   //       quantity: el.quantity || 1,
  //   //       price_id: el.priceId,
  //   //       ad_hoc_amount: el.defaultAmount,
  //   //     });
  //   //   }
  //   // });
  //   // return line_items;
  // }

  getSessionId() {
    // check url first.
    const checkoutId = getQueryArg(window.location.href, 'checkout_id');
    if (!!checkoutId) {
      return checkoutId;
    }

    // check existing order.
    if (checkoutState?.checkout?.id) {
      return checkoutState?.checkout?.id;
    }

    // we don't have and order id.
    return null;
  }

  async fetchCheckout(id, { query = {}, data = {} } = {}) {
    try {
      updateFormState('FETCH');
      const checkout = (await createOrUpdateCheckout({
        id,
        query,
        data,
      })) as Checkout;
      updateFormState('RESOLVE');
      return checkout;
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  /** Fetch a session. */
  async fetch(query = {}) {
    try {
      updateFormState('FETCH');
      checkoutState.checkout = (await fetchCheckout({
        id: this.getSessionId(),
        query,
      })) as Checkout;
      updateFormState('RESOLVE');
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  /** Update a session */
  async update(data: any = {}, query = {}) {
    try {
      checkoutState.checkout = (await createOrUpdateCheckout({
        id: data?.id ? data.id : this.getSessionId(),
        data,
        query,
      })) as Checkout;
    } catch (e) {
      // reinitalize if order not found.
      if (['checkout.not_found'].includes(e?.code)) {
        clearCheckout();
        return this.initialize();
      }
      console.error(e);
      throw e;
    }
  }

  /** Updates a session with loading status changes. */
  async loadUpdate(data = {}) {
    try {
      updateFormState('FETCH');
      await this.update(data);
      updateFormState('RESOLVE');
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  render() {
    return (
      <sc-line-items-provider order={checkoutState?.checkout} onScUpdateLineItems={e => this.loadUpdate({ line_items: e.detail as Array<LineItemData> })}>
        <slot />
      </sc-line-items-provider>
    );
  }
}
