:host {
  display: block;
}

sc-table {
  height: auto;
}

h4 {
  display: block;
  margin: 0;
  font-weight: var(--sc-font-weight-bold);
  font-size: var(--sc-font-size-medium);
}

.stock-alert {
  &__image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    display: block;
  }
  &__quantity {
    color: var(--sc-color-gray-500);
    font-weight: var(--sc-font-weight-bold);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--sc-spacing-xx-small);
  }
}
